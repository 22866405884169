<style lang="scss">
    @import "~@/assets/css/var";
    .nav-left {
        display: flex;
        height: 100%;
        &-jump {
            width: 300px;
            height: 100%;
            background: #fff url(~@/assets/img/nav-left/nav-back.png) no-repeat bottom center / 100% auto;
            color: #333;

            &__title {
                padding: 50px 0 30px;
                font-size: 18px;
                font-weight: bold;
            }

            &__item {
                padding: 10px 0 10px 25px;
                font-size: 15px;
                background: url(~@/assets/img/nav-left/all.png) no-repeat left center /17px auto;
                color: #666;
                cursor: pointer;
                &:hover, &.active {
                    color: $main;
                }
                .hot {
                    width: 30px;
                    margin-left: 3px;
                    vertical-align: 5px;
                }
                &.all {
                    background-image: url(~@/assets/img/nav-left/all.png);
                    &:hover, &.active {
                        background-image: url(~@/assets/img/nav-left/all-check.png);
                    }
                }
                &.product {
                    background-image: url(~@/assets/img/nav-left/product.png);
                    &:hover, &.active {
                        background-image: url(~@/assets/img/nav-left/product-check.png);
                    }
                }
                &.corp {
                    background-image: url(~@/assets/img/nav-left/corp.png);
                    &:hover, &.active {
                        background-image: url(~@/assets/img/nav-left/corp-check.png);
                    }
                }
                &.service {
                    background-image: url(~@/assets/img/nav-left/service.png);
                    &:hover, &.active {
                        background-image: url(~@/assets/img/nav-left/service-check.png);
                    }
                }
                &.analysis {
                    background-image: url(~@/assets/img/nav-left/analysis.png);
                    &:hover, &.active {
                        background-image: url(~@/assets/img/nav-left/analysis-check.png);
                    }
                }
            }
        }

        &-panel {
            flex: 1;
            padding: 40px 0 0 60px;
            background: #f7f7f7;


            &__list {
                display: flex;
                margin-top: 30px;
            }
            &__item {
                width: 180px;
                margin-right: 40px;
            }
            &__title {
                width: 150px;
                line-height: 15px;
                font-size: 15px;
                font-weight: 400;
                color: #000;
                padding-bottom: 19px;
                margin-bottom: 19px;
                border-bottom: 1px solid #DCDCDC;
            }
            &__desc {
                font-size: 13px;
                line-height: 13px;
                font-weight: 400;
                color: #333;
                padding-bottom: 20px;
                cursor: pointer;
                &:hover {
                    color: $main;
                }
                .hot {
                    display: inline-block;
                    width: 30px;
                }
            }
        }
    }
</style>
<template>
    <div class="nav-left">
        <div class="nav-left-jump">
            <div style="width:150px;margin:0 auto">
                <p class="nav-left-jump__title">淘数导航</p>
                <div class="nav-left-jump__list">
                    <div class="nav-left-jump__item all" @click="goPage('all')">查看全部产品</div>
                    <div class="nav-left-jump__item product" @click="goPage('product')" :class="{active: $route.path.indexOf('/product') > -1}">
                        热门产品<img class="hot" src="@/assets/img/hot.png" alt="hot">
                    </div>
                    <div class="nav-left-jump__item corp" @click="goPage('corp')"
                         :class="{active: $route.path.indexOf('/corp') > -1}">企业所需入口</div>
                    <div class="nav-left-jump__item service" @click="goPage('service')"
                         :class="{active: $route.path.indexOf('/service') > -1}">服务商所需入口</div>
                    <div class="nav-left-jump__item analysis" @click="goPage('analysis')"
                         :class="{active: $route.path.indexOf('/analysis') > -1}">助手分析入口</div>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        methods: {
            goPage(go) {
                if (this.$route.path.indexOf(go) === -1) {
                    if(go === 'all') {
                        this.newPath(`/nav-all`)
                    } else {
                        this.$router.push(`/nav/${go}`)
                    }
                }
            },
            newPath(url){
                const { href } = this.$router.resolve({
                    path: url,
                })
                window.open(href, "_blank")
            },
        }
    }
</script>